<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
      div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`, { number: sailorDocument.number }).toUpperCase() }}
        span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
      ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate)
  v-card-text
    v-divider
    Info(
      v-if="sailorDocument.behavior.viewInfoBlock"
      :sailorDocument="sailorDocument")

    Form(
      v-else-if="sailorDocument.behavior.viewEditBlock"
      :sailorDocument="sailorDocument")

    EditStatus(
      v-else-if="sailorDocument.behavior.viewEditStatusBlock"
      :sailorDocument="sailorDocument")

    ViewPhotoList(
      v-else-if="sailorDocument.behavior.viewFilesBlock"
      :sailorDocument="sailorDocument"
      :documentType="type")
</template>

<script>
import { viewDetailedComponent, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SailorStudentDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorStudent/Info.vue'),
    Form: () => import('@/views/Sailor/SailorStudent/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorStudent/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      checkAccess,
      type: 'student',
      isLoading: true,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('student', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('student', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('student', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('student', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-close',
          action: () => this.goBack(),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]

    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId
    }),
    ...mapGetters(['sailorDocumentByID']),
    sailorDocument () {
      const { documentID } = this.$route.params
      return this.sailorDocumentByID({ type: 'student', id: Number(documentID) }) || {}
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getStudentCard', 'deleteCadetCard']),
    goBack () {
      this.$router.push({ name: 'education-student', params: { id: this.id } })
    },
    async getData () {
      if (!Object.keys(this.sailorDocument).length) await this.getStudentCard({ ...this.$route.params })
      this.isLoading = false
    },
    deleteDocument () {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.deleteCadetCard({ id: this.sailorDocument.id, sailorDocument: this.sailorDocument })
          if (response) this.goBack()
        }
      })
    }
  }
}
</script>
